.nav {
    width: 90vw;
    height: 50px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    min-width: 350px;
}

.page-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.social-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.icon {
    width: var(--icon-size);
    height: auto;
    margin: 10px;
}

.icon:hover {
    outline: 1px solid var(--main-hover-color);
}

.link {
    text-decoration: none;
    height: auto;
    margin: 10px;
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    font-size: var(--main-font-size);
    cursor: pointer;
}

.link:hover {
    -webkit-text-stroke: 0.25px var(--main-hover-color);
}

@media screen and (max-width: 500px) {
    .link {
        font-size: var(--main-font-size-mobile);
    }
    .icon {
        width: var(--icon-size-mobile);
    }
}