.Home_Section {
  width: 90vw;
  min-width: 350px;
  height: 500px;
  margin: auto;
  background-color: var(--main-bg-color);
  border: 2px solid var(--main-border-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.Home_Text {
  width: 40%;
  min-width: 250px;
  max-width: 500px;
  height: auto;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.Portrait {
  width: 40%;
  min-width: 200px;
  max-width: 500px;
  height: auto;
  margin: auto;
  margin-bottom: 0;
}

.Home_Header {
  font-family: var(--main-font-family);
  font-size: var(--header-font-size);
}

.Home_Subtitle {
  font-family: var(--main-font-family);
  font-size: var(--subheader-font-size);
}

.Home_Main {
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  text-align: center;
}

@media screen and (max-width: 900px) {
  .Home_Section {
    flex-direction: column;
  }

  .Home_Text {
    width: 90%;
  }

  .Portrait{
    max-width: 275px;
  }

  .Home_Header {
    font-size: var(--header-font-size-tablet);
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .Home_Subtitle {
    font-size: var(--subheader-font-size-tablet);
    margin-top: 0;
  }

  .Home_Main {
    font-size: var(--main-font-size-tablet);
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .Home_Section {
    flex-direction: column;
  }
  .Home_Header {
    font-size: var(--header-font-size-mobile);
  }

  .Home_Subtitle {
    font-size: var(--subheader-font-size-mobile);
  }

  .Home_Main {
    font-size: var(--main-font-size-mobile);
  }
}