.Projects_Section {
    width: 90vw;
    height: 500px;
    min-width: 350px;
    margin: auto;
    background-color: var(--main-bg-color);
    border: 2px solid var(--main-border-color);
    text-align: center;
    overflow: scroll;
}

.Project_Header {
    font-family: var(--main-font-family);
    font-size: var(--header-font-size);
    margin-top: 50px;
    margin-bottom: 50px;
}

.Project_Container {
    width: 90%;
    max-width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    justify-content: space-evenly;
}

.Project_Div {
    width: 90%;
    max-width: 400px;
    height: auto;
    margin-top: 0;
    margin-bottom: 50px;
    background-color: white;
    border: 2px solid var(--main-border-color);
    box-shadow: 1px 1px 10px;
    overflow: hidden;
}

.Project_Div:hover {
    box-shadow: 1px 1px 20px;
}

.Project_Link {
    height: auto;
    width: auto;
    text-align: center;
    text-decoration: none;
}

.Project_Img {
    width: 100%;
    height: auto;
}

.Project_Div:hover .Project_Img{
    filter: brightness(20%);
}

.Project_Div:hover .Img_Title{
    opacity: 1;
}


.Project_Text {
    font-family: var(--main-font-family);
    font-size: var(--footer-font-size);
    color: var(--main-font-color);
    width: 80%;
    margin: auto;
    padding: 20px;
}

.Icon_Div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 20%;
    margin: auto;
}

.Project_Icon {
    width: 30px;
    height: auto;
}

.Img_Title{
    position: relative;
    top: -30px;
    width: auto;
    margin: 0;
    font-family: var(--main-font-family);
    font-size: var(--subheader-font-size-mobile);
    color: var(--secondary-font-color);
    text-shadow: 2px 2px 2px black;
    text-align: center;
    opacity: 0;
}
@media screen and (max-width: 900px) {
    .Project_Container {
        flex-direction: column;
    }

    .Project_Header {
        font-size: var(--header-font-size-tablet);
    }
}

@media screen and (max-width: 500px) {
    .Project_Container {
        flex-direction: column;
    }

    .Project_Header {
        font-size: var(--header-font-size-mobile);
    }
}