@font-face {
  font-family: "ocr-a-std", monospace;
  font-weight: 200;
  font-style: normal;
  src: url("https://use.typekit.net/agq7tel.css");
}

:root {
  --main-bg-color: rgba(240, 248, 255, 0.9);
  --main-border-color: black;
  --main-font-family: "ocr-a-std";
  --main-font-color: black;
  --secondary-font-color: white;
  --main-hover-color: yellow;
  --main-font-size: 16px;
  --main-font-size-tablet: 14px;
  --main-font-size-mobile: 12px;
  --header-font-size: 60px;
  --header-font-size-tablet: 50px;
  --header-font-size-mobile: 40px;
  --subheader-font-size: 22px;
  --subheader-font-size-tablet: 20px;
  --subheader-font-size-mobile: 18px;
  --footer-font-size: 10px;
  --icon-size: 30px;
  --icon-size-mobile: 20px;
}

body,
html {
  margin: 0px auto;
  height: 100%;
  overflow: hidden;
  min-width: 320px;
}

.wrapper {
  padding: 20px;
  height: 100%;
}

.loading-animation{
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  animation: fade forwards;
  animation-duration: 1s;
  animation-delay: 1s;
  margin-top: 40vh;
}

@keyframes fade {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
  
}