.footer{
    margin: auto;
    width: 90vw;
    min-width: 350px;
    height: 50px;
    text-align: left;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer-links{
    width: 50%;
    height: auto;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: right;
}

.footer-text{
    font-family: var(--main-font-family);
    font-size: var(--footer-font-size);
}

.footer-link {
    text-decoration: none;
    height: auto;
    margin-left: 20px;
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    font-size: var(--main-font-size);
    cursor: pointer;
}

.footer-link:hover {
    -webkit-text-stroke: 0.25px var(--main-hover-color);
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: var(--main-font-color);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 10px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

@media screen and (max-width: 800px) {
    .footer-link {
        font-size: var(--main-font-size-mobile);
    }
}